class Base {
  constructor() {}
  makeazero = (v) => {
    return ("0" + v).slice(-2)
  }
  roundTime = (DateVal) => {
    let D = DateVal ? new Date(DateVal) : new Date();
    return `${this.makeazero(D.getMonth() + 1)}月${this.makeazero(D.getDate())}号 ${this.makeazero(D.getHours())}:${this.makeazero(D.getMinutes())}`
  }

  picPath(oss,url,w,h){
    return `${oss}/${url}${w ? `?x-oss-process=image/resize,m_fixed,w_${w},h_${h}`:''}`
  }
  def1 = (DateVal) => {
    let D = DateVal ? new Date(DateVal) : new Date();
    const result = `${D.getFullYear()}.${D.getMonth() +
      1}.${D.getDate()} ${D.getHours()}:${D.getMinutes() < 10 ? "0" + D.getMinutes() : D.getMinutes()
      }`;
    return result;
  };
  async sleep(ms, after = null) {
    await new Promise((resolve) => setTimeout(resolve, ms));
    after&&after()
  }
  beforeClose(done, callback) {
    this.$confirm("确认关闭？")
      .then(() => {
        callback && callback();
        done();
      })
      .catch(() => {});
  }
  handleClose(done, callback) {
    this.$confirm("确认关闭？")
      .then(() => {
        callback && callback();
        done();
      })
      .catch(() => {});
  }
  reductionTo = (m, n) => {
    const ratioConfig = (x, y) => ({
      x,
      y,
      ratio: x / y,
    });

    const commonRatio = [
      ratioConfig(1, 1),
      ratioConfig(4, 3),
      ratioConfig(3, 2),
      ratioConfig(5, 3),
      ratioConfig(5, 4),
      ratioConfig(16, 9),
      ratioConfig(16, 10),
    ];

    const getRatioConfig = (x, y) => {
      const ratio = x / y;
      const rank = [...commonRatio]
        .map((r) => ({
          ...r,
          delta: Math.abs(r.ratio - ratio),
        }))
        .sort((r1, r2) => r1.delta - r2.delta);
      return ratioConfig(rank[0].x, rank[0].y);
    };

    const ret = getRatioConfig(m, n);
    // console.log(ret.x, ret.y); // 4, 3
    return [ret.x, ret.y];
  };
  readImg(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (e) => (img.src = e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
    });
  }
  getDataType(data) {
    return Object.prototype.toString.call(data).slice(8, -1);
  }
  formatDate(data, formatStr = "YYYY-MM-DD hh:mm:ss") {
    // 待扩展

    if (!data) return "";
    // formatStr = 'YYYY-MM-DD hh:mm:ss'

    if (this.getDataType(data) === "String") {
      if (/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/.test(data)) {
        data = data.replace(/-/g, "/");
      }
    }

    var date = new Date(data);

    var Y = date.getFullYear();
    var M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    let formatDate = formatStr
      .replace("YYYY", Y)
      .replace("MM", M)
      .replace("DD", D)
      .replace("hh", h)
      .replace("mm", m)
      .replace("ss", s);

    return formatDate;
  }
  showResponseMsg(vueInstance, res, baseTipMsg, call, skip) {
    // console.log(vueInstance, res, baseTipMsg, "showResponseMsg");
    let message, type;

    switch (res.statusCode) {
      case 200:
        message = `${baseTipMsg}成功！`;
        type = "success";
        if (skip) {
          if (call) {
            call(type == "success");
          }
          return;
        }
        break;
      case -1:
        message = `${baseTipMsg}失败！ 系统报错：${res.errMsg}！`;
        type = "error";
        break;
      case -2:
        message = `${baseTipMsg}失败！ 参数有误：${res.errMsg}！`;
        type = "error";
        break;
      case 1:
        message = `${baseTipMsg}失败！ 业务出错：${res.errMsg}！`;
        type = "error";
        break;
      default:
        message = res.errMsg ? res.errMsg : JSON.stringify(res);
        type = "error";
        break;
    }

    if (message) {
      vueInstance.$message({
        showClose: true,
        message,
        type,
        onClose: () => {},
      });
    }
    if (call) {
      call(type == "success");
    }
  }
  copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
}

let instance = new Base();

export { Base, instance as baseInstance };
