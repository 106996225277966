/**
 Author: wwh
 Date: 2021-06-22 11:19:31
 LastEditTime: 2022-02-17 17:49:13
 LastEditors: wwh
 Description: 
 FilePath: \client-vue\src\api\user.js
 
 */

import request from "@/request/request";
const module = "/auth";
const path = "/api" + module;

export const getUserInfo222 = (params) =>
  request.get(`${path}/member/grades/by/userID/`, { params });
export const getUserByPhone = (params) =>
  request.post(`${path}/users/phone/`, params);

export const check = () => request.get(`${path}/check/`);
export const getUsersByIDs = (params) =>
  request.post(`${path}/new/users/`, params);
export const createUserAndBindRoles = (params) =>
  request.post(`${path}/create/user/roles/`, params);

export const getMemberInfo = (params) =>
  request.get(`${path}/new/user/details/by/userID/`, { params });

// 删除用户
export const deleteUser = (id, reason) =>
  request.delete(`${path}/user/${id}?reason=${reason}`, {});

export const newUserList = (params) =>
  request.get(`${path}/new/users/by/`, { params });

// 用户列表，旧接口
export const getUserList = (params) =>
  request.get(`${path}/user/`, { params });

// 用户列表，新接口
export const getUserListOfNew = (params) =>
  request.get(`${path}/new/users/`, { params });

// 新增用户
export const userAdd = (params) => request.post(`${path}/user/`, params);
// 更新用户
export const userUpdate = (params) =>
  request.put(`${path}/user/${localStorage.getItem("userid")}`, params);

// 用户详情
export const userDetails = (params) =>
  request.get(`${path}/user/${localStorage.getItem("userid")}`, {
    params,
  });

// 删除用户
export const userDelete = (params) =>
  request.delete(`${path}/user/${localStorage.getItem("userid")}`, {
    params,
  });

// 根据用户ID去拿权限列表
export const userConfig = (params) =>
  request.get(`${path}/user/${localStorage.getItem("userid")}/rbac/`, {
    params,
  });

// 根据用户ID去拿用户得权限值
export const userRights = (params) =>
  request.get(`${path}/user/${localStorage.getItem("userid")}/right/`, {
    params,
  });

// 更新用户权限值
export const userRightUpdate = (userId, right) =>
  request.put(`${path}/user/${userId}/right/`, right);

// 用户关联角色
export const userLinkRole = (userId, params) =>
  request.post(`${path}/user/${userId}/role/`, params);

// 用户取消关联角色
export const userNoLinkRole = (userId, params) =>
  request.delete(`${path}/user/${userId}/role/`, { params });

// 根据用户ID去查询所绑定的角色
export const getBindRolesByUser = (userid) =>
  request.get(`${path}/user/${userid}/role/`);

// 通过用户id查询所绑定的模块和权限值
export const getUserBindAppModlueMsg = (userid, params) =>
  request.get(`${path}/user/${userid}/application/`, { params });

// 用户关联角色
export const updateUserSelfRight = (userId, params) =>
  request.put(`${path}/user/${userId}/right/`, params);

// 通过用户ID集合查询用户信息
export const getUserListByBatch = (userids) =>
  request.post(`${path}/users/`, userids);

/** 密码管理 */

// 超管重置用户密码
export const resetUserPassword = (params) =>
  request.post(`${path}/password/reset/admin/`, params);

// 修改密码，旧接口，废弃
// export const resetPassword = params => request.post(`${path}/password/reset/`, params);

// 修改密码，新接口
export const resetPassword = (params) =>
  request.post(`${path}/new/password/reset/`, params);

/** 验证码管理 */

// 发送验证码，新接口
export const sendCaptcha = (params) =>
  request.get(`${path}/send/captcha/`, { params });

// 校验验证码，新接口
export const checkCaptcha = (params) =>
  request.get(`${path}/check/captcha/`, { params });
