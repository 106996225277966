import Vue from "vue";
import Vuex from "vuex";
import test from "./test_store";
import user from "./user_store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 定义应用的状态
    ...test.state,
    ...user.state,
  },
  mutations: {
    // 定义修改状态的方法
    ...test.mutations,
    ...user.mutations,
  },
  actions: {
    // 定义触发mutations的方法
    ...test.actions,
    ...user.actions,
  },
  getters: {
    // 定义获取state中数据的方法
    ...test.getters,
    ...user.getters,
  },
});
