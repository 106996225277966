import { getUsersByIDs, newUserList } from "@/api/user";
import { getRoleList } from "@/api/role";
export default {
  data() {
    return {
      phoneUserList: [],
      nameUserList: [],
      roles: {
        key: null,
        page: 1,
        pageSize: 9999,
      },
      usersT: {
        page: 1,
        pageSize: 10,
        email: null,
        phone: null,
        name: null,
        roleID: null,
        ids: null,
        id: null,
      },
      rolesT: {
        page: 1,
        pageSize: 10,
        key: null,
      },
    };
  },
  watch: {},
  methods: {
    getUserName(obj) {
      if (typeof obj == "undefined") {
        return;
      }
      let temp = obj.lastName + obj.firstName;
      if (temp.length > 0) {
        return temp;
      }
      if (obj.nickname && obj.nickname.length > 0) {
        return obj.nickname;
      }
      if (obj.nickName && obj.nickName.length > 0) {
        return obj.nickName;
      }
      return `用户${obj.id}`;
    },
    getImageUrl(obj) {
      // console.log("here", obj);
      let temp = require(`@/assets/image/defaultAvatar.png`);
      if (!obj) {
        return temp;
      }
      // console.log("getImageUrl", obj.avatarUrl, obj.photoUrl, obj);
      if (obj.main_pic) {
        return localStorage.getItem("oss") + obj.main_pic;
      }
      if (obj.photoUrl) {
        return localStorage.getItem("oss") + obj.photoUrl;
      }
      if (obj.avatarUrl) {
        return obj.avatarUrl;
      }
      // console.log(temp);
      return temp;
    },
    async getUserList(obj = null) {
      let { page, pageSize, email, phone, name, ids, id, roleID } =
        obj == null ? this.users : obj;
      ids = JSON.stringify(ids);
      let res = await newUserList({
        page,
        pageSize,
        roleID,
        email,
        phone,
        name,
        ids,
        id,
      });
      res.data.list.forEach((e) => {
        e.NAME = this.getUserName(e);
        e.AVATAR = this.getImageUrl(e);
        this.$store.commit("addUser", e);
      });
      return res;
    },
    async getRoleList(obj) {
      let { key, page, pageSize } = obj == null ? this.roles : obj;
      let res = await getRoleList({ key, page, pageSize });
      res.data.list= res.data.list.filter((e) => e.id <= 3 || e.id > 31);
      this.roles = { ...this.roles, ...res.data };
      return res;
    },
    async getUserListByPhone(phone, u, what) {
      let payload = {
        page: 1,
        pageSize: 10,
        phone,
      };
      if (u) {
        payload.user = u;
      }

      let res = await newUserList(payload);
      let list = res.data ? res.data.list : [];
      let arr=[]
      if(what){
        let obj = this.phoneUserList.find(e=>e.value==what)
        arr=[obj]
      }
      this.phoneUserList =[...arr,... list.map((e) => {
        e.NAME = this.getUserName(e);
        e.AVATAR = this.getImageUrl(e);
        this.$store.commit("addUser", e);
        return {
          value: e.id,
          label: `手机号码：${e.cellphone} 用户名：${e.NAME}; `,
        };
      })];
    },
    async getUserListByName(key, u) {
      let payload = {
        page: 1,
        pageSize: 10,
        name: key,
      };
      if (u) {
        payload.user = u;
      }

      let [err, res] = await this.$sessionManager.flat(newUserList(payload));

      if (!err) {
        // console.log("getUserListByPhone_res", res);
        // 兼容处理
        let {
          data: { users: list },
        } = res;
        this.nameUserList = list.map((e) => {
          e.NAME = this.getUserName(e);
          e.AVATAR = this.getImageUrl(e);
          this.$store.commit("addUser", e);
          return {
            value: e.id,
            label: `手机号码：${e.cellphone} 用户名：${e.NAME}; `,
          };
        });
      }
    },
    async getUserInfo(uids) {
      if (uids.length > 0) {
        let uids2 = uids.filter((e) => !this.$store.getters.isIn(e) && e);
        if (uids2.length > 0) {
          let res = await getUsersByIDs(uids2.filter((e) => e));
          res.data &&
            res.data.forEach((e) => {
              e.NAME = this.getUserName(e);
              e.AVATAR = this.getImageUrl(e);
              this.$store.commit("addUser", e);
            });
        }
        this.$forceUpdate();
      }
    },
  },
};
