/**
 Author: wwh
 Date: 2021-06-22 11:19:31
 LastEditTime: 2022-03-23 18:25:22
 LastEditors: wwh
 Description: 
 FilePath: \client-vue\src\api\auth.js
 
 */

import request from "@/request/request";

const module = "/auth";
const path = "/api" + module;

export const getWxJsSdkSignatrue = (url) =>
  request.get(`${path}/middleground/signature/?url=${url}`);

export const getCurrentPath = (params) =>
  request.get(`${path}/currentPath/`, { params });
export const getWxPageUrlLinkBase = (params) =>
  request.get(`${path}/wxMpUrlLinkBase/`, { params });
export const CreateWxPageUrlLinkBase = (params) =>
  request.post(`${path}/wxMpUrlLinkBase/`, params);
export const getWxPage = (params) => request.get(`${path}/wxPage/`, { params });
export const createWxPage = (params) => request.post(`${path}/wxPage/`, params);
export const editWxPage = (params) => request.put(`${path}/wxPage/`, params);

// 获取当前用户菜单列表
export const getDynamicMsgOfUser = (params) =>
  request.get(`${path}/menu/`, { params });

// 查看后台拥有各模块
export const getModuleConfigMsg = (params) =>
  request.get(`${path}/admin/project/`, { params });

// 登陆
export const loginApi = (params) => request.post(`${path}/signin/cms/`, params);

//获取提现审核需要的openid
export const getOpenid = (params) =>
  request.get(`${path}/wx/openid/${localStorage.getItem("userid")}`, {
    params,
  });

// 获取用户信息
export const getUserInfo = (params) =>
  request.get(`${path}/userinfo/`, { params });

// 通过用户id数组批量查询用户名称
export const getUserNameListByUserIDS = (params) =>
  request.post(`${path}/new/names/by/ids/`, params);

export const userLinkPM = (params) =>
  request.post(`${path}/link/file/users/`, params);
export const roleLinkPM = (params) =>
  request.post(`${path}/link/file/roles/`, params);
export const getLinkInfo = (params) =>
  request.post(`${path}/get/users/roles/by/path/`, params);

export const getTag = (params) => request.post(`${path}/get/tags/`, params);
export const createTag = (params) =>
  request.post(`${path}/create/tags/`, params);
export const updateTag = (params) =>
  request.put(`${path}/update/tags/`, params);
export const linkTag = (params) =>
  request.post(`${path}/link/main/user/tags/`, params);
export const test = () => request.get(`${path}/test/`);

export const getOssConfig = () => request.get(`/api/oss/config/`);
export const getPK = (params) =>
  request.post(`/api/oss/pk/`, params);
