<template>
  <div class="inline">
    <div
      @click="
        (e) => {
          e.stopPropagation();
          isVisible(true);
        }
      "
      style="width: fit-content; height: fit-content"
    >
      <slot>
        <el-button>test</el-button>
      </slot>
    </div>
    <el-dialog
      :visible.sync="visible"
      :title="title"
      append-to-body
      destroy-on-close
      :width="width"
      :before-close="beforeClose"
    >
      <slot
        name="dialog"
        :visible="visible"
        :close="
          () => {
            isVisible(false);
          }
        "
      ></slot>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "showDialogBtn",
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "65%",
    },
    beforeClose: {
      type: Function,
      default: (done) => {
        done();
      },
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    isVisible(f) {
      this.visible = f;
    },
  },
};
</script>
