<template>
  <div id="app">
    <VuexTest v-if="false" />
    <Home v-if="isLogin == true" :menus="menus" />
    <LoginPage v-if="isLogin == false" />
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import { initAdminMenu, getMyMenu } from "@/api/role";
import { getUserInfo, getOssConfig } from "@/api/auth";
import VuexTest from "./components/VuexTest.vue";
import pages, { clean } from "@/router/pages";
import Home from "@/pages/home_page.vue";
import LoginPage from "@/pages/login_page.vue";
import usermix from "@/mixins/user";
export default {
  name: "App",
  mixins: [usermix],
  components: {
    // HelloWorld,
    VuexTest,
    Home,
    LoginPage,
  },
  data() {
    return {
      isLogin: null,
      menus: [],
    };
  },
  watch: {},
  async created() {
    let res = await getOssConfig();
    this.$setGP("oss", `https://${res.data.Bucket}.${res.data.Endpoint}`);
    window.onerror = function (message, source, lineno, colno, error) {
      console.error("捕获到异常：", { message, source, lineno, colno, error });
    };
  },

  async mounted() {
    // console.error(`准备补上 initAdminMenu({ data: all });`);
    initAdminMenu({ data: JSON.stringify(this.$util.copy(pages).map((e) => clean(e))) });
    // this.console = console;
    this.$setGP("console", console);
    let token = sessionStorage.getItem("token");
    if (this.$dev) {
      token = localStorage.getItem("token");
      sessionStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
    if (token) {
      let res3 = await getUserInfo();
      let userinfo = {
        ...res3.data,
        name: this.getUserName(res3.data),
        url: this.getImageUrl(res3.data),
      };
      sessionStorage.setItem("userInfo", JSON.stringify(userinfo));
      this.$setGP("$userinfo", userinfo);
      if (!this.menus.length) {
        await this.generateMenus();
      }
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  methods: {
    assign(obj, base) {
      let temp = base.find((e) => e.path == obj.path);
      // console.log(obj, base, temp);
      if (temp) {
        let { children, ...left } = temp;
        // console.log("left", left, "obj", obj);
        obj = Object.assign(obj, left);
        // console.log("assign", obj.children, children);
        if (obj.children && children) {
          // obj.children = [...obj.children, ...children.filter((e) => e.hide)];
          obj.children.forEach((e) => this.assign(e, children));
          // console.log(
          //   "hide",
          //   children.filter((e) => e.hide)
          // );
          obj.children = [...obj.children, ...children.filter((e) => e.hide)];
        }
      } else {
        console.error("delete this", obj);
        obj.bad = true;
      }
    },
    async generateMenus() {
      let res = await getMyMenu();
      let menu = JSON.parse(res.data);
      menu.forEach((e) => this.assign(e, pages));
      // console.log("menu", menu);
      // console.error("拿用户信息 凑一个 路由表 出来", pages);
      this.menus = menu;
      menu.forEach((e) => {
        this.$router.addRoute("dynamic", e);
      });
      this.$setGP("MENU", menu);
      // console.error(
      //     `添加新路由this.$router.addRoutes([ { path: '/dynamic', component: DynamicComponent }]);`
      //   );
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: PingFang SC-Heavy, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  -ms-overflow-style: none;
  overflow: -moz-hidden-unscrollable;
}
#app::-webkit-scrollbar {
  width: 0 !important;
}
li {
  list-style: none;
}
</style>
