/**
 Author: wwh
 Date: 2021-06-22 11:19:31
 LastEditTime: 2021-10-22 09:51:22
 LastEditors: wwh
 Description: 
 FilePath: \client-vue\src\api\role.js
 
 */
import request from "@/request/request";
// const path =
//   process.env.NODE_ENV === "development"
//     ? "/api"
//     : process.env.VUE_APP_FLAG === "test"
//     ? ""
//     : "";
// const shopPath = process.env.NODE_ENV === 'development' ? '/shop' : (process.env.VUE_APP_FLAG === 'test' ? '' : '')

const module = "/auth";
const path = "/api" + module;
export const getAllRoleMenu = () => request.get(`${path}/menu/all/`);

export const getMyMenu = () => request.get(`${path}/menu/my/`);

export const updateRoleMenu = (params) =>
  request.put(`${path}/menu/`, params);
export const initAdminMenu = (params) =>
  request.post(`${path}/menu/`, params);

export const bindUsersAndRoles = (params) =>
  request.post(`${path}/bind/role/`, params);
export const UnBindUsersAndRoles = (data) =>
  request.delete(`${path}/bind/role/`, { data });

export const deleteRole = (id) => request.delete(`${path}/role/${id}`, {});
export const updateRoleAuthRight = (id, right) =>
  request.put(`${path}/role/${id}/right/`, right);
export const updateRoleRightByApplicationID = (aid, rid, right) =>
  request.put(`${path}/save/application/${aid}/role/${rid}`, right);
// 获取角色列表
export const getRoleList = (params) =>
  request.get(`${path}/role/`, { params });

// 新增角色
export const roleAdd = (params) => request.post(`${path}/role/`, params);

// 角色详情
export const roleDetails = (id) => request.get(`${path}/role/${id}`);

// 根据角色ID拿对应的权限值
export const roleRight = (params) =>
  request.get(`${path}/role/${sessionStorage.getItem("roleID")}/right/`, {
    params,
  });
// 根据角色ID拿对应的角色权限列表
export const roleRbac = (params) =>
  request.get(`${path}/role/${sessionStorage.getItem("roleID")}/rbac/`, {
    params,
  });

// 根据角色ID拿对应的用户
export const getRoleUser = (roleId, params) =>
  request.get(`${path}/role/${sessionStorage.getItem("roleID")}/user/`, {
    params,
  });

// 更新角色
export const roleUpdate = (params) =>
  request.put(`${path}/role/${sessionStorage.getItem("roleID")}`, params);
// 更改角色对应的权限值
export const roleRightUpdate = (params) =>
  request.put(
    `${path}/role/${sessionStorage.getItem("roleID")}/right/`,
    params
  );
// 删除角色
export const roleDelete = (params) =>
  request.delete(`${path}/role/${sessionStorage.getItem("roleID")}`, {
    params,
  });

// 角色关联用户
export const roleLinkUser = (roleId, params) =>
  request.post(`${path}/role/${roleId}/user/`, params);
// 角色不关联用户
export const roleNoLinkUser = (roleId, params) =>
  request.delete(`${path}/role/${roleId}/user/`, { params });

// 通过角色id查询所有绑定的模块信息和权限值
export const getRoleBindAppModlueMsg = (roleId, params) =>
  request.get(`${path}/role/${roleId}/application/`, { params });

// 根据权限值拿对应得权限列表
export const rbacByrights = (module, rightCount) =>
  request.get(`${path}/${module}/rbac/${rightCount}`);

export const getUserRole =()=>request.get(`${path}/exist/roles/by/uid/chain/`)