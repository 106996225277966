export const base = [];
import VueRouter from "vue-router";

const routerGenerator = (children = []) => {
  let router = new VueRouter({
    routes: [
      { path: "/", redirect: "/home" },
      {
        path: "/home",
        title: "首页",
        component: () => import("@/pages/default_page"),
      },
      ...children,
    ],
    mode: "hash",
  });
  router.beforeEach(async (to, from, next) => {
    to, from;
    // console.log(from, to);
    next();
  });
  return router;
};
export default routerGenerator;

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
