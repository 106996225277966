<template>
  <div>
    <template v-if="item.children">
      <el-submenu :index="index">
        <template slot="title">
          <i :class="item.icon"></i>
          <span class="noselect">{{ item.title }}</span>
        </template>
        <MySubMenu
          v-for="(v, k) in item.children"
          :key="k"
          :item="v"
          :p="item.path"
          :click="click"
        >
        </MySubMenu>
      </el-submenu>
    </template>
    <template v-if="!item.children">
      <el-menu-item
        v-if="!item.hide"
        :index="index"
        @click="
          () => {
            click({ ...item, index, p }, rootMenu);
          }
        "
      >
        <!-- <img
        v-if="item.icon"
        :src="require(`@/assets/icon/menu/${item.icon}.png`)"
        class="mr-16 w-20 h-20"
      /> -->
        <!-- <el-icon v-if="item.icon"> <component :is="item.icon"></component> </el-icon> -->

        <i :class="item.icon"></i>
        <span class="noselect"> {{ item.title }} </span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  inject: ["rootMenu"],
  name: "mySubMenu",
  props: {
    item: Object,
    p: { type: String, default: "" },
    click: Function,
  },
  data() {
    return {
      index: null,
    };
  },
  created() {
    // if (!this.item.children && this.p != "") {
    //   this.index = this.p + this.item.path;
    // } else {
    this.index = this.item.path;
    // }
  },
};
</script>
<style scoped lang="less"></style>
