import axios from "axios";
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
import Vue from "vue";
import { baseInstance } from "@/utils/base";
Vue.config.productionTip = false;
Vue.prototype.$setGP = (k, v) => {
  Vue.prototype[k] = v;
};
Vue.prototype.$util = baseInstance;
Vue.prototype.$dev = require("../package.json").dev;
Vue.prototype.$version = require("../package.json").version;

import VueRouter from "vue-router";
Vue.use(VueRouter);

import mySubMenu from "@/components/MySubmenu.vue";
Vue.component("MySubMenu", mySubMenu);

import "@/styles/common.less";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/theme/index.css";
// import "@/styles/element-variables.scss";

import ElementUI from "element-ui";
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
import routerGenerator from "@/router/index";
import App from "./App.vue";
import store from "./store";
let router = routerGenerator();
new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});
