import MainView from "@/components/MainView";
const _user = {
  path: "/user",
  title: "用户管理",
  icon: "el-icon-user-solid",
  component: MainView,
  children: [
    {
      path: "/user/list",
      title: "用户列表",
      component: () => import("@/pages/user/user_index.vue"),
    },
    // {
    //   path: "/user/role",
    //   title: "角色列表",
    //   component: () => import("@/pages/user/role_index.vue"),
    // },
  ],
};
const _rp = {
  path: "/rp",
  title: "积分管理",
  icon: "el-icon-s-finance",
  component: MainView,
  children: [
    {
      path: "/rp/list",
      title: "积分列表",
      component: () => import("@/pages/rp/rp_index.vue"),
    },
    {
      path: "/rp/log",
      title: "积分日志",
      component: () => import("@/pages/rp/rp_log.vue"),
    },
    {
      path: "/rp/good/list",
      title: "积分商品",
      component: () => import("@/pages/rp/rp_good.vue"),
    },
    {
      path: "/rp/good/order",
      title: "积分商品订单",
      component: () => import("@/pages/rp/rp_good_order.vue"),
    },
  ],
};
const _task = {
  path: "/task",
  title: "任务管理",
  icon: "el-icon-s-order",
  component: MainView,
  children: [
    {
      path: "/task/list",
      title: "任务列表",
      component: () => import("@/pages/task/task_index.vue"),
    },
  ],
};
_task;
export default [_user, _rp];
export const clean = function (page) {
  if (page.children && page.children.length) {
    for (let index = 0; index < page.children.length; index++) {
      let element = page.children[index];
      clean(element);
    }
  }
  delete page.component;
  return page;
};
