var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100vh","display":"grid","grid-template-columns":"220px 1fr"}},[_c('div',{staticClass:"left flexBox flexdColumn",staticStyle:{"height":"100vh"},attrs:{"menu":""}},[_c('div',{staticClass:"flexBox allCenter menuhead"},[_c('el-button',{attrs:{"type":"text"},on:{"click":() => {
            _vm.$router.push({
              path: '/home',
            });
            _vm.defaultActive = '/home';
          }}},[_c('span',{staticClass:"fs-18 fw-6",staticStyle:{"color":"white"}},[_vm._v(" 运营管理后台 ")])])],1),_c('div',{staticClass:"rest mt-24",staticStyle:{"overflow-y":"auto"}},[_c('el-menu',{ref:"menu",staticClass:"el-menu-vertical-demo",staticStyle:{"border-right-color":"white","text-align":"left"},attrs:{"router":"","unique-opened":"","background-color":"white","text-color":"black"}},_vm._l((_vm.menus.filter((e) => Object.keys(e).length)),function(item,key){return _c('MySubMenu',{key:key,attrs:{"item":item,"click":(item) => {
              _vm.newTab(item);
            }}})}),1)],1),_c('div',{staticClass:"fs-14 fw-6 lh-40 version",attrs:{"align":"center"}},[_vm._v("版本号v"+_vm._s(_vm.$version))])]),_c('div',{staticClass:"right flexBox flexdColumn",staticStyle:{"background-color":"aliceblue"}},[_c('ResetPassword',{attrs:{"account":_vm.$userinfo.cellphone,"callBack":() => {
          _vm.location.reload();
        }}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"resetpassword"},[_vm._v("asd")])]),_c('div',{staticClass:"flexBox righthead"},[_c('div',{staticClass:"rest flexBox",staticStyle:{"color":"white","align-items":"center","justify-content":"flex-end"}},[_c('span',{staticClass:"mr-8",staticStyle:{"min-width":"50px"}},[_vm._v(_vm._s(_vm.$userinfo.name))]),_c('el-dropdown',{staticClass:"mr-24",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',[_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"editPassword"}},[_vm._v("修改密码")]),_c('el-dropdown-item',{attrs:{"command":"exit"}},[_vm._v("退出登录")])],1)],1)],1)]),_c('div',{staticClass:"h-40",staticStyle:{"flex":"0 0 40px","background-color":"#ffffff","width":"calc(100vw - 220px)"}},[_c('el-tabs',{on:{"tab-click":({ paneName }) => _vm.clickTab(paneName),"tab-remove":(path) => {
            _vm.console.log(path);
            delete _vm.tabs[path];
            _vm.$forceUpdate();
            _vm.p = '/home';
            if (path == _vm.defaultActive && _vm.defaultActive != _vm.p) {
              _vm.defaultActive = _vm.p;
              _vm.$router.push(_vm.p);
            }
          }},model:{value:(_vm.defaultActive),callback:function ($$v) {_vm.defaultActive=$$v},expression:"defaultActive"}},[_c('el-tab-pane',{attrs:{"name":"/home"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"mx-12 el-icon-house noselect"})])]),_vm._l((Object.keys(_vm.tabs)),function(item,key){return _c('el-tab-pane',{key:key,attrs:{"name":item,"closable":""}},[_c('span',{staticClass:"noselect",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.tabs[item].title))])])})],2)],1),_c('div',{staticClass:"rest"},[_c('div',{staticClass:"m-12",staticStyle:{"height":"calc(100vh - 114px)","background-color":"white","overflow-y":"auto"},attrs:{"id":"main"}},[_c('MainView')],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }