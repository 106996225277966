export default {
  state: {
    userMap: {},
  },
  //同步
  mutations: {
    addUsers(state, users) {
      users.forEach((e) => {
        state.userMap[e.id] = e;
      });
    },
    addUser(state, user) {
      state.userMap[user.id] = user;
    },
    removeUser(state, id) {
      delete state.userMap[id];
    },
  },
  //异步
  actions: {},
  getters: {
    getUserByID: (state) => (id) => {
      return state.userMap[id];
    },
    getAllUsers: (state) => state.userMap,
    isIn: (state) => (id) => {
      return state.userMap[id] ? true : false;
    },
  },
};
