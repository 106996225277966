<template>
  <div style="height: 100vh; display: grid; grid-template-columns: 220px 1fr">
    <div menu class="left flexBox flexdColumn" style="height: 100vh">
      <div class="flexBox allCenter menuhead">
        <el-button
          type="text"
          @click="
            () => {
              $router.push({
                path: '/home',
              });
              defaultActive = '/home';
            }
          "
        >
          <span class="fs-18 fw-6" style="color: white"> 运营管理后台 </span>
        </el-button>
      </div>
      <div class="rest mt-24" style="overflow-y: auto">
        <!-- active-text-color="$--color-primary" -->
        <el-menu
          ref="menu"
          router
          unique-opened
          background-color="white"
          text-color="black"
          class="el-menu-vertical-demo"
          style="border-right-color: white; text-align: left"
        >
          <MySubMenu
            v-for="(item, key) in menus.filter((e) => Object.keys(e).length)"
            :key="key"
            :item="item"
            :click="
              (item) => {
                newTab(item);
              }
            "
          />
        </el-menu>
      </div>
      <div class="fs-14 fw-6 lh-40 version" align="center">版本号v{{ $version }}</div>
    </div>
    <div class="right flexBox flexdColumn" style="background-color: aliceblue">
      <ResetPassword
        :account="$userinfo.cellphone"
        :callBack="
          () => {
            location.reload();
          }
        "
      >
        <button ref="resetpassword" v-show="false">asd</button>
      </ResetPassword>
      <div class="flexBox righthead" style="">
        <div
          class="rest flexBox"
          style="color: white; align-items: center; justify-content: flex-end"
        >
          <!-- <span class="w-37 h-37 mr-8 flexBox allCenter">
            <img
              :src="$userinfo.url"
              style="width: 100%; height: 100%; border-radius: 50%"
              alt=""
            />
          </span> -->

          <span class="mr-8" style="min-width: 50px">{{ $userinfo.name }}</span>
          <el-dropdown class="mr-24" trigger="click" @command="handleCommand">
            <span>
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
              <el-dropdown-item command="exit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div
        class="h-40"
        style="flex: 0 0 40px; background-color: #ffffff; width: calc(100vw - 220px)"
      >
        <el-tabs
          v-model="defaultActive"
          @tab-click="({ paneName }) => clickTab(paneName)"
          @tab-remove="
            (path) => {
              console.log(path);
              delete tabs[path];
              $forceUpdate();
              p = '/home';
              if (path == defaultActive && defaultActive != p) {
                defaultActive = p;
                $router.push(p);
              }
            }
          "
        >
          <el-tab-pane name="/home">
            <span slot="label"><i class="mx-12 el-icon-house noselect"></i></span>
          </el-tab-pane>
          <!-- <el-tab-pane name="/home">
            <template #label>
              <el-icon class="mx-12"><House /></el-icon> </template
          ></el-tab-pane> -->
          <el-tab-pane
            v-for="(item, key) in Object.keys(tabs)"
            :key="key"
            :name="item"
            closable
          >
            <span class="noselect" slot="label">{{ tabs[item].title }}</span>
            <!-- <template #label>
              <span class="noselect" v-if="tabs[item]">
                {{ tabs[item].title }}
              </span>
            </template> -->
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="rest" style="">
        <div
          id="main"
          class="m-12"
          style="height: calc(100vh - 114px); background-color: white; overflow-y: auto"
        >
          <MainView />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResetPassword from "@/components/user/ResetPassword";
import MainView from "@/components/MainView";
export default {
  name: "index-home",
  props: {
    menus: Array,
  },
  components: { MainView, ResetPassword },
  watch: {},
  data() {
    return {
      rootMenu: null,
      defaultActive: "/home",
      tabs: {},
    };
  },
  created() {
    this.location = location;
    this.$setGP("$newTab", this.newTab);
  },
  mounted() {
    let temp = JSON.parse(sessionStorage.getItem("currentTabs"));
    if (temp) {
      this.newTab(temp);
    }
  },
  methods: {
    newTab(obj) {
      let { index, title, p, query = {} } = obj;
      sessionStorage.setItem("currentTabs", JSON.stringify({ index, p, title }));
      this.addTab(obj);
      this.clickTab(index, query);
    },
    clickTab(paneName, query = {}) {
      this.$router.push({ path: paneName, query });
      if (paneName != "/home") {
        this.$refs.menu.open(this.tabs[paneName].p);
        this.$refs.menu.activeIndex = this.tabs[paneName].index;
      } else {
        this.$refs.menu.activeIndex = null;
      }
    },
    addTab(item) {
      if (!this.tabs[item.index]) {
        this.tabs[item.index] = { ...item };
      }
      this.defaultActive = item.index;
    },
    handleCommand(n) {
      switch (n) {
        case "editPassword":
          this.$refs.resetpassword.click();
          break;
        case "exit":
          sessionStorage.removeItem("token");
          localStorage.removeItem("token");
          location.reload();
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/element-variables.scss";
.righthead {
  background-color: $--color-primary;
  flex: 0 0 50px;
}
.menuhead {
  background-color: $--color-primary;
  color: white;
  flex: 0 0 50px;
}
.version {
  color: white;
  background: $--color-primary;
  flex: 0 0 40px;
}
.left {
  // flex: 0 0 220px;
  // background: #304156;
  // background: white;
  // transition: margin-left 0.5s;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
.right {
  transition: margin-left 0.5s;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
:deep(.el-tabs__nav) {
  margin-left: 24px;
}
:deep(.el-tabs__item.is-active) {
  color: $--color-primary;
}
:deep(.el-tabs__item:hover) {
  color: $--color-primary;
}

:deep(.el-tabs__active-bar) {
  background-color: $--color-primary;
}
:deep(.el-menu-item.is-active) {
  color: black;
  background-color: rgba(107, 255, 224, 0.2);
  border-left: 8px solid $--color-primary;
  font-weight: 700;
}
:deep(.el-menu-item) {
  color: black;
}
:deep(.el-submenu) {
  color: black;
}
</style>
