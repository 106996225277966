<template>
  <div class="flexBox allCenter bg" style="width: 100vw; height: 100vh">
    <div class="py-50 px-100 bg2 pt-100" style="">
      <el-form ref="loginForm" :model="loginForm" label-width="60px" :rules="loginRules">
        <el-form-item label="账号" prop="account">
          <el-input v-model="loginForm.account" placeholder="请输入账号"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <div class="flexBox">
          <el-form-item>
            <el-checkbox v-model="rememberMe">记住账号</el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="
                $refs.loginForm.validate((e) => {
                  if (e) {
                    isShow = true;
                  }
                })
              "
              >登录</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
    </div>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import { loginApi, test } from "@/api/auth";
export default {
  components: {
    Vcode,
  },
  data() {
    return {
      isShow: false,
      loginForm: {
        account: "",
        password: "",
      },
      loginRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      rememberMe: false,
    };
  },
  async created() {
    await test();
    let account = localStorage.getItem("USER_ACCOUNT");
    if (account) {
      this.loginForm.account = account;
      this.rememberMe = true;
    }
  },
  methods: {
    onSuccess() {
      this.isShow = false; // 通过验证后，需要手动关闭模态框
      this.handleLogin();
    },

    onClose() {
      this.isShow = false;
    },
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          console.log("account:", this.loginForm.account);
          console.log("Password:", this.loginForm.password);
          console.log("Remember Me:", this.rememberMe);

          if (this.rememberMe) {
            localStorage.setItem("USER_ACCOUNT", this.loginForm.account);
          }
          this.isShow = false;
          console.error("调用登录api");
          let res = await loginApi(this.loginForm);
          // console.log(res);
          if (res.id) {
            this.$message({ type: "success", message: "登录成功" });
            sessionStorage.setItem("token", res.accessToken);
            if (this.$dev) {
              localStorage.setItem("token", res.accessToken);
            }
            // sessionStorage.setItem("refreshToken", res.refreshToken);
            // sessionStorage.setItem("uid", res.userID);
            location.reload();
          }
          // Perform login action here, including handling 'Remember Me'
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/element-variables.scss";
.bg {
  background-color: $--color-text-regular;
}
.bg2 {
  background-color: $--color-white;
  border-radius: 20px;
  box-shadow: 0 18px 28px 0 rgba(66, 141, 215, 0.3);
}
</style>
