import axios from "axios";
import {
    Loading as ElLoading,
    Message as ElMessage
} from "element-ui"
import qs from "qs";
const service = axios.create({
  timeout: 66000, // 单位：毫秒
  headers: {
    "current-version": "1.1.00",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
let count = 0;
let loading;
let noLoadingList = {
  "/operation/activityRaffle/mini/": 1,
};
//请求前拦截
service.interceptors.request.use(
  (config) => {
    // config是axios配置对象
    if (
      count === 0 &&
      !noLoadingList[config.url.replace("/api", "")] &&
      !(
        (config.params && config.params.noLoad) ||
        (config.data && config.data.noLoad)
      )
    ) {
      loading = ElLoading.service({
        target: ".content",
        background: "rgba(255, 255, 255, 0.3)",
      });
    }
    count++;
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    if (
      config.method === "post" ||
      config.method === "put" ||
      config.method === "patch"
    ) {
      if (config.params) {
        config.url += "?" + qs.stringify(config.params);
        delete config.data;
      } else {
        config.data = JSON.stringify(config.data);
      }
    } else if (config.method === "get") {
      config.params = config.params || {};
      if (config.params == true) {
        config.url += "?" + qs.stringify(config.params);
        config.data = JSON.stringify(config.data);
        delete config.params;
      }
    } else if (config.method === "delete") {
      console.log("delete_config", config);

      if (config.data) {
        config.data = JSON.stringify(config.data);
      } else {
        config.data = JSON.stringify(config.params);
      }
      console.log("delete_config", config);

    }
    return config;
  },
  (error) => {
    console.warn(error)
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;
  if (!config || !config.retry) return Promise.reject(err);
  config.__retryCount = config.__retryCount || 0;
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err);
  }

  config.__retryCount += 1;

  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });

  return backoff.then(function () {
    return axios(config);
  });
});

//响应结果后拦截
service.interceptors.response.use(
  (response) => {
    count--;
    if (count === 0) {
      loading ? loading.close() : null;
    }
    if (response.data) {
      let { data } = response;
      if (data.statusCode && data.statusCode != 200) {
        console.log("asldkjaslkdas", response);
        ElMessage.error({ message: data.errMsg ,showClose:true});
      }
    }
    return response.data;
  },
  (error) => {
    console.warn("error", error);
    if (error.config.timeout == 500) {
      ElMessage.error("请求超时，请检查网络");
    }

    let { status, data } = error.response;

    let setupLogin = () => {
      sessionStorage.removeItem("token"); // 登录会话凭证已过期，清楚缓存数据，跳转登录页面
      localStorage.removeItem("token"); // 登录会话凭证已过期，清楚缓存数据，跳转登录页面
      ElMessage.error({ message: "登录凭证已失效！，正在跳转登录页..." });
      history.go(0);
    };

    switch (status) {
      case 400:
        if (data === "auth: invalid token") {
          setupLogin();
        } else {
          ElMessage.error({ message: data });
        }

        break;

      case 401:
        if (data === "auth: invalid token") {
          setupLogin();
        } else {
          ElMessage.error({ message: data });
        }
        // setupLogin();

        break;
      // case 400:
      //     ElMessage.error({message:"已存在，请重试！"});
      //     break;
      case 500:
        ElMessage.error({ message: "服务器内部错误" });
        break;
      case 404:
        ElMessage.error({ message: "找不到该页面" });
        break;
      case 403:
        ElMessage.error({ message: "权限不足,请联系管理员!" });
        break;
      case 502:
        ElMessage.error({ message: "错误代码：502，服务器进程挂了!" });
        break;
      default:
        ElMessage.error({ message: data });
    }
    loading ? loading.close() : null;

    // Message({
    //     Message: '登陆失败' || '请求失败',
    //     type: 'error',
    //     duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

//导出实例
export default service;

// /*第一层if判断生产环境和开发环境*/
// if (process.env.NODE_ENV === `'production'`) {
//     /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
//     if (process.env.VUE_APP_FLAG === 'pro') {
//         // production 生产环境
//         axios.defaults.baseURL = 'https://yiyan.yiyan.pro';

//     } else {
//         //test 测试环境
//         axios.defaults.baseURL = 'https://dev-yiyan.xinyinhao.cn';
//     }
// } else {
//     //dev 开发环境
//     axios.defaults.baseURL = 'https://dev-yiyan.xinyinhao.cn';
// }
