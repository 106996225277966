<template>
  <ShowDialogBtn title="修改密码">
    <div style="width: fit-content; height: fit-content">
      <slot>
        <button>test1</button>
      </slot>
    </div>
    <template #dialog="dialog">
      <div v-if="dialog.visible">
        <el-form label-width="90px" ref="form" :model="form" :rules="loginRules">
          <div class="flexBox">
            <el-form-item class="half" label="密码" prop="password" align="left">
              <el-input
                type="password"
                style="width: 100%"
                v-model="form.password"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="half"
              label="确认密码"
              prop="confirmPassword"
              align="left"
            >
              <el-input
                type="password"
                style="width: 100%"
                v-model="form.confirmPassword"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="mt-12 rg-12 cg-12" align="center">
          <div>
            <el-button type="primary" @click="reset(dialog.close)">确认</el-button>
            <el-button @click="dialog.close()">取消 </el-button>
          </div>
        </div>
      </div>
    </template>
  </ShowDialogBtn>
</template>
<script>
import ShowDialogBtn from "@/components/ShowDialogBtn";
import { resetUserPassword } from "@/api/user";
export default {
  components: { ShowDialogBtn },
  props: ["account", "callBack"],
  methods: {
    async reset(ca) {
      let res = await resetUserPassword({
        account: this.account,
        password: this.form.password,
      });
      this.$util.showResponseMsg(this, res, "修改密码", (t) => {
        if(t){
          ca&&ca()
          this.callBack&&this.callBack()
        }
      });

    },
  },
  data() {
    let form = { password: null, confirmPassword: null };
    let checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请确认密码"));
      } else if (value != this.form.password) {
        return callback(new Error("两次密码不一致"));
      }
      callback();
    };
    return {
      form,
      resetForm: this.$util.copy(form),
      loginRules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        // { required: true, message: "请确认密码", trigger: "blur" }
        confirmPassword: [{ validator: checkPassword, trigger: "blur" }],
      },
    };
  },
};
</script>
